import request, { $post } from '@/utils/request.js'
import { cookie } from '@/utils/tools'
import { Toast } from 'vant'
// 用户请求登录
export function login(data) {
  return request({
    method: 'get',
    url: 'users/login',
    params: data
  })
}

export function forgetPwd(data) {
  return request({
    method: 'get',
    url: 'users/forget',
    params: data
  })
}

// 用户登录后的信息
export function userinfo() {
  return request({
    method: 'get',
    url: 'users/info'
  })
}

// 请求发送验证码的函数
export function sendSms(data) {
  return request({
    method: 'GET',
    url: 'users/sms',
    params: data

  })
}

// 请求提交添加支付密码
export function submitPassword(data) {
  return request({
    method: 'GET',
    url: 'users/edit/paypass',
    params: data
  })
}

// 获取银行信息
export function userBank() {
  return request({
    method: 'GET',
    url: 'users/bank'
  })
}

// 添加银行卡
export function addBank(data) {
  return request({
    method: 'GET',
    url: 'users/bank/add',
    params: data
  })
}

// 获取用户信息
export function userInfo() {
  return request({
    method: 'GET',
    url: 'users/info'
  })
}
// 请求withDraw 列表数据
export function getExtractList(data) {
  return request({
    method: 'GET',
    url: 'users/extract/lists',
    params: data

  })
}
// 提现提交
export function extract(data) {
  return request({
    method: 'GET',
    url: 'users/extract',
    params: data
  })
}

// 修改登录密码
export function newloginPassword(data) {
  return request({
    method: 'GET',
    url: 'users/edit/userpass',
    params: data
  })
}

// 获取một nơi人中心信息
export function usersmessage(data) {
  return request({
    method: 'GET',
    url: 'users/message',
    params: data
  })
}

// 修改用户数据
export function editUserInfo(data) {
  return request({
    method: 'POST',
    url: 'users/edit',
    params: data
  })

  return $post('users/edit', data, {})
  // .then((res) => {
  //   if (res.data.ret == 1) {
  //     Promise.resolve(res.data.data)
  //   } else {
  //     Promise.reject(res.data.msg)
  //   }
  // }).catch((err) => {
  //   Promise.reject(err.data.msg)
  // })
}


export const GET_USERINFOUNEW = (params) => new Promise((resolve, reject) => {
  $post('/users/info', params).then((r) => {
    if (cookie.get('token')) {
      if (r.data.ret === 1) {
        cookie.set('userInfo', JSON.stringify(r.data.data))
        cookie.set('token', r.data.data.token)
        return resolve(r.data)
      }
      return reject(r.data)
    }
  }).catch((e) => {
    return reject(e)
  })

})

export const editAvatar = (data) => new Promise((resolve, reject) => {
  $post('/users/edit/avatar', data, {}).then((res) => {
    if (res.data.ret == 1) {
      resolve(res.data)
    }
    reject(res.data)
  }).catch(e => {
    reject(e)
  })

})